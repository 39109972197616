import React, { useEffect, useState } from "react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsF,
} from "@coreui/react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { MdCategory } from "react-icons/md";
import { SiMaterialdesignicons } from "react-icons/si";
import { RiOutlet2Fill } from "react-icons/ri";
import { LiaUsersSolid } from "react-icons/lia";
import { FaClipboardUser, FaUsersGear } from "react-icons/fa6";
import { PiUsersFourFill } from "react-icons/pi";
import { GrProjects } from "react-icons/gr";
import { TbShoppingCartStar } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

import { FaStore, FaUsers, FaUserSlash, FaUserTag } from "react-icons/fa";
import {
  FiPackage,
  FiXCircle,
  FiHome,
  FiUsers,
  FiUserPlus,

} from "react-icons/fi";
import { format } from "date-fns";

const Dashboard = () => {
  const accessToken = localStorage.getItem("authToken");
  const [outlets, setOutlets] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState('');
  const [selectedOutlet, setSelectedOutlet] = useState("All Outlets");
  const [topProducts, setTopProducts] = useState([]);
  const [newlyAddedProducts, setNewlyAddedProducts] = useState([]);
  const [filterBy, setFilterBy] = useState('salesCount');
  const [CountLoading, setCountLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCounts, setAllCounts] = useState({});

  const navigate = useNavigate();

  const formattedDate = new Date(endDate).toLocaleDateString();

  useEffect(() => {
    const now = new Date();
    const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    // Set the start date and end date
    setStartDate(firstOfMonth);
    setEndDate(currentDate);
  }, []);

  useEffect(() => {
    getAllCount();
    // getOrdersCount();
  }, [])

  // const getOrdersCount = async (startDate, endDate, outlet) => {
  //   setCountLoading(true);
  //   try {
  //     const headers = {
  //       Authorization: `Bearer ${accessToken}`,
  //     };

  //     let params = {
  //       startDate: startDate ? startDate.toISOString().split("T")[0] : null,
  //       endDate: endDate ? endDate.toISOString().split("T")[0] : null,
  //     };

  //     if (outlet !== "All Outlets") {
  //       params.outlet = outlet;
  //     }

  //     const response = await axios.get("order/getOrdersForAdmin", {
  //       headers,
  //       params,
  //     });
  //     setOrderCount(response?.data?.data?.orderData);
  //     setTopProducts(response?.data?.data?.topTenProducts);
  //     setNewlyAddedProducts(response?.data?.data?.newlyAddedProducts)
  //   } catch (error) {
  //     if (error?.response) {
  //       let errorMessage = '';
  //       if (
  //         error.response?.status === 406
  //       ) {
  //         errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
  //       }
  //       else if (
  //         error.response?.status === 412
  //       ) {
  //         errorMessage = error?.response?.data?.data?.message;
  //       } else if (error.response?.status === 500) {
  //         errorMessage = error?.response?.data?.error
  //       }

  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         theme: "colored",
  //       });
  //     }
  //   } finally {
  //     setCountLoading(false);
  //   }
  // };

  const getAllCount = async() => {
    try {
      const headers = {
        Authorization : `Bearer ${accessToken}`
      }
      const response = await axios.get("getAllData", { headers });
      setAllCounts(response?.data);
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleEndDateChange = (date) => {
    if (date && startDate && date < startDate) {
      setError('End date cannot be earlier than start date');
    } else {
      setError('');
      setEndDate(date);
    }
  };

  // const handleSubmit = async () => {

  //   const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
  //   const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

  //   const formatDate = (date) => {
  //     return date.toISOString().split('T')[0]; // Format: '2024-07-31'
  //   };
  //   setLoading(true);
  //   await getOrdersCount(adjustedStartDate, adjustedEndDate, selectedOutlet);
  //   toast.success("Data Fetched Successfully", {
  //     position: "top-right",
  //     autoClose: 3000,
  //     theme: "colored",
  //   });
  //   setLoading(false);
  // };

  const handleFilterChange = (e) => {
    setFilterBy(e.target.value);
  };

  const sortedProducts = Array.isArray(topProducts) ?
    [...topProducts].sort((a, b) => {
      if (filterBy === 'salesCount') {
        return b.totalQuantity - a.totalQuantity;
      } else if (filterBy === 'revenue') {
        return b.totalRevenue - a.totalRevenue;
      }
    }) : [];

  const top10Products = sortedProducts.slice(0, 10);

  return (
    <div className="container">
      <CRow>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3 cursor-pointer"
            color="primary"
            icon={<MdCategory size={25} />}
            title="Categories"
            value={allCounts?.Categories}
            onClick={() => navigate("/masters/categories")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<SiMaterialdesignicons size={25} />}
            title="Raw Material Main Category"
            value={allCounts?.MainRMCategory}
            onClick={() =>
              navigate("/masters/rm-main-category")
            }
            style={{ cursor: "pointer", fontSize: '14px' }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="warning"
            icon={<SiMaterialdesignicons size={25} />}
            title="Raw Material Sub Category"
            value={allCounts?.SubRMCategory}
            onClick={() =>
              navigate("/masters/rm-sub-category")
            }
            style={{ cursor: "pointer", fontSize: '14px' }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="danger"
            icon={<RiOutlet2Fill size={25} />}
            title="Outlets"
            value={allCounts?.Outlets}
            onClick={() => navigate("/outlet-management/outlets")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="primary"
            icon={<LiaUsersSolid size={25} />}
            title="Vendors"
            value={allCounts?.Vendors}
            onClick={() => navigate("/vendors")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<FaUsersGear size={25} />}
            title="admin roles"
            value={allCounts?.Roles}
            onClick={() => navigate("/masters/admin-roles")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="warning"
            icon={<PiUsersFourFill size={25} />}
            title="Outlet Users"
            value={allCounts?.Outlets}
            onClick={() => navigate("/outlet-management/outletusers")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="danger"
            icon={<SiMaterialdesignicons size={25} />}
            title="Raw Materials"
            value={allCounts?.RawMaterials}
            onClick={() => navigate("/masters/rawmaterials")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="primary"
            icon={<GrProjects size={25} />}
            title="Products"
            value={allCounts?.Products}
            onClick={() => navigate("/product-management/products-catalog")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<TbShoppingCartStar size={25} />}
            title="Procurements"
            value={allCounts?.Procurements}
            onClick={() => navigate("/central-inventory/procurements-list")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={6} lg={4} xl={3}>
          <CWidgetStatsF
            className="mb-3"
            color="warning"
            icon={<TbShoppingCartStar size={25} />}
            title="Product Addon"
            value={allCounts?.ProductAddons}
            onClick={() => navigate("/product-management/product-addon")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
      </CRow>

      <hr />
      {/* <------------------- Over all details section -----------------> */}
      {/* <CRow className="mt-4">
        <CCol md={4}>
          <CFormSelect
            id="selectField"
            name="selectField"
            value={selectedOutlet}
            onChange={(e) => setSelectedOutlet(e.target.value)}
          >
            <option value="">All Outlets</option>

            {outlets.map((outName, index) => {
              return (
                <option key={index} value={outName?._id}>
                  {outName?.name}
                </option>
              );
            })}
          </CFormSelect>
        </CCol>
        <CCol className="col-md-3" md={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Select Start Date"
              className="form-control"
            />
          </div>
        </CCol>
        <CCol className="col-md-3" md={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ReactDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select End Date"
              className="form-control"
            />
            {error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div>}
          </div>
        </CCol>

        <CCol xs={12} md={2}>
          <CButton color="primary" className="w-100" onClick={handleSubmit} disabled={loading}>
            {loading ? <CSpinner size="sm" /> : 'Submit'}
          </CButton>
        </CCol>
      </CRow> */}

      {/* <------------- Order Details Analysis Section --------------> */}
      <CRow>
        <h5 className="mt-4">Users Analysis</h5>
      </CRow>
      <CRow className="mt-3">
        <CCol sm={4} md={3} >
          <CCard className="mb-3" color="success" textColor="white">
            <CCardHeader>Total Users</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FaUsers  size={30} className="me-3" />
                <h4>{allCounts?.totalUsersCount ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={4} md={3}>
          <CCard className="mb-3" color="primary" textColor="white">
            <CCardHeader>Total Referral Users</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FaUserTag size={30} className="me-3" />
                <h4>{allCounts?.totalUsersWithReferral ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={4} md={3}>
          <CCard className="mb-3" color="info" textColor="white">
            <CCardHeader>Kiosk Users</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FaClipboardUser size={30} className="me-3" />
                <h4>{allCounts?.totalDistinctKioskUsersCount ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={3}>
          <CCard className="mb-3" color="danger" textColor="white">
            <CCardHeader>Total Deleted Accounts</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FaUserSlash size={30} className="me-3" />
                <h4>{allCounts?.totalDeletedAccountsCount ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {/* <----------------- Users Detail Analysis Section -----------------> */}
      <CRow>
        <h5 className="mt-4">Order Analysis</h5>
      </CRow>
      <CRow className="mt-3">
        <CCol md={6}>
          <CCard
            className="mb-3"
            style={{ backgroundColor: "#1cc88a", color: "white" }}
          >
            <CCardHeader>Total Completed Orders</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FiPackage size={30} className="me-3" />
                <h4>{allCounts?.completedOrdersCount ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={6}>
          <CCard
            className="mb-3"
            style={{ backgroundColor: "#f75c5c", color: "white" }}
          >
            <CCardHeader>Total Cancelled Orders</CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <FiXCircle size={30} className="me-3" />
                <h4>{allCounts?.cancelledOrdersCount ?? "0"}</h4>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {/* <---------------------- Products Analytics Section -------------------> */}
      {/* <CRow>
        <h5 className="mt-4">Product Analytics</h5>
      </CRow>
      <CRow className="align-items-center mb-3">
        <CCol xs="auto">
          <h6 className="mt-3">Top 10 Products</h6>
        </CCol>
        <CCol xs={12} sm={6} md={4} className="ms-auto">
          <CFormSelect
            aria-label="Filter by"
            value={filterBy}
            onChange={handleFilterChange}
          >
            <option value="salesCount">Sales Count</option>
            <option value="revenue">Revenue</option>
          </CFormSelect>
        </CCol>
      </CRow> */}

      {/* <CRow className="mt-3">
        <CCol>
          <CTable striped bordered hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Rank</CTableHeaderCell>
                <CTableHeaderCell>Product Name</CTableHeaderCell>
                <CTableHeaderCell>Category</CTableHeaderCell>
                <CTableHeaderCell>MRP</CTableHeaderCell>
                <CTableHeaderCell>Offer Price</CTableHeaderCell>
                <CTableHeaderCell>Sales Count</CTableHeaderCell>
                <CTableHeaderCell>Revenue</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {top10Products?.length > 0 ? (
                top10Products?.map((product, index) => (
                  <CTableRow key={product?.id}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{product?.product?.name}</CTableDataCell>
                    <CTableDataCell>{product?.categoryName}</CTableDataCell>
                    <CTableDataCell className="text-end">₹. {product?.product?.mrp}</CTableDataCell>
                    <CTableDataCell className="text-end">₹. {product?.product?.offerPrice}</CTableDataCell>
                    <CTableDataCell className="text-end" style={{ width: '12%' }}>{product?.totalQuantity}</CTableDataCell>
                    <CTableDataCell className="text-end">₹. {product?.totalRevenue.toFixed(2)}</CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan="7" className="text-center text-danger fw-5">
                    No Products Found
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </CCol>
      </CRow> */}

      {/* <CRow className="mt-4">
        <h6>Newly added Products</h6>
      </CRow> */}

      {/* <CRow className="mt-3">
        <CCol>
          <CTable striped bordered hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Sr.No</CTableHeaderCell>
                <CTableHeaderCell>Product Name</CTableHeaderCell>
                <CTableHeaderCell>Category</CTableHeaderCell>
                <CTableHeaderCell>Class Type</CTableHeaderCell>
                <CTableHeaderCell>Food Type</CTableHeaderCell>
                <CTableHeaderCell>Date Added</CTableHeaderCell>
                <CTableHeaderCell>Price</CTableHeaderCell>
                <CTableHeaderCell>Offer Price</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {newlyAddedProducts?.length > 0 ? (
                newlyAddedProducts?.map((product, index) => (
                  <CTableRow key={product.id}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{product?.name}</CTableDataCell>
                    <CTableDataCell>{product?.category?.name}</CTableDataCell>
                    <CTableDataCell>{product?.classType}</CTableDataCell>
                    <CTableDataCell className="text-center">
                      <CBadge className="w-100" color={product?.foodType === 'VEG' ? 'success' : product?.foodType === 'VEGAN' ? 'warning' : 'danger'}>{product?.foodType}</CBadge>
                    </CTableDataCell>
                    <CTableDataCell>
                      {format(new Date(product?.createdAt), 'MMM d, yyyy')}
                    </CTableDataCell>
                    <CTableDataCell className="text-end">₹. {product?.mrp}</CTableDataCell>
                    <CTableDataCell className="text-end">₹. {product?.offerPrice}</CTableDataCell>
                  </CTableRow>
                ))) : (
                <CTableRow>
                  <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                    No Products Found
                  </CTableDataCell>
                </CTableRow>
              )
              }
            </CTableBody>
          </CTable>
        </CCol>
      </CRow> */}
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { CBadge, CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CImage, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTooltip } from '@coreui/react';
import { LuEye } from 'react-icons/lu';
import { MdDelete, MdEdit } from 'react-icons/md';
import { DeleteModal, PrimaryButton } from '../../components';
import { FaPlus } from 'react-icons/fa';

const Level = () => {
  const accessToken = localStorage.getItem("authToken");
  const [levels, setLevels] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [levelId, setLevelId] = useState("");
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [inputData, setInputData] = useState({
    title: "",
    sequenceNo: "",
    pointsEarningPercent: "",
    boosterPercent: "",
    requiredPoints: ""
  });
  const [bannerImage, setBannerImage] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const imageInputRef = useRef(null);
  const [isOpenUpdateImageModal, setIsOpenUpdateImageModal] = useState(false);
  const [isImageUpdating, setIsImageUpdating] = useState(false)

  const getLevels = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("level", { headers });
      if (response?.data?.status === true) {
        const levels = response?.data?.data?.levels;
        setLevels(levels)
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setLevelId(data?._id);
  };

  const handleDeleteLevel = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: levelId,
      };

      const response = await axios.delete(`level`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getLevels();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let sanitizedValue = value;

    const isPositiveInteger = (val) => {
      const num = Number(val);
      return Number.isInteger(num) && num >= 0;
    };

    if (name === "pointsEarningPercent" || name === "boosterPercent" || name === "requiredPoints") {
      if (isPositiveInteger(value)) {
        sanitizedValue = value;
      } else {
        sanitizedValue = "";
      }
    }

    if (name === "title") {
      sanitizedValue = value;
    }

    setInputData((prev) => ({ ...prev, [name]: sanitizedValue }));
  };

  const handleBannerImageChange = () => {
    const input = document.getElementById("imageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 1920;
          const maxHeight = 1080;

          if (width === maxWidth && height === maxHeight) {
            setBannerImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const addLevel = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", inputData.title);
      formData.append("sequenceNo", inputData.sequenceNo);
      formData.append("pointsEarningPercent", inputData.pointsEarningPercent);
      formData.append("requiredPoints", inputData?.requiredPoints)
      formData.append("bannerImage", bannerImage);

      if (inputData?.boosterPercent !== "") {
        formData.append("boosterPercent", inputData?.boosterPercent);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("level", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        e.target.reset();
        getLevels();
        setInputData({
          title: "",
          sequenceNo: "",
          pointsEarningPercent: "",
        });
        setBannerImage(null)
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      _id: data?._id,
      title: data?.title,
      sequenceNo: data?.sequenceNo,
      pointsEarningPercent: data?.pointsEarningPercent,
      boosterPercent: data?.boosterPercent,
      requiredPoints: data?.requiredPoints
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    let sanitizedValue = value;

    const isPositiveInteger = (val) => {
      const num = Number(val);
      return Number.isInteger(num) && num >= 0;
    };

    if (name === "pointsEarningPercent" || name === "boosterPercent" || name === "requiredPoints") {
      if (isPositiveInteger(value)) {
        sanitizedValue = value;
      } else {
        sanitizedValue = "";
      }
    }

    if (name === "title") {
      sanitizedValue = value
    }

    setEditedData((prev) => ({ ...prev, [name]: sanitizedValue }));
  }

  const handleEditBannerImage = () => {
    const input = document.getElementById("imageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 1920;
          const maxHeight = 1080;

          if (width === maxWidth && height === maxHeight) {
            setBannerImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("_id", editedData?._id)
      formData.append("title", editedData.title);
      formData.append("sequenceNo", editedData.sequenceNo);
      formData.append("pointsEarningPercent", editedData.pointsEarningPercent);
      formData.append("requiredPoints", editedData?.requiredPoints);
      if (editedData?.boosterPercent !== "") {
        formData.append("boosterPercent", editedData?.boosterPercent);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("level", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getLevels()
        setOpenEditModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const openUpdateImageModal = (data) => {
    setEditedData((prev) => ({
      ...prev,
      _id: data?._id
    }))
    setIsOpenUpdateImageModal(true)
  }

  const handleEditImage = async (e) => {
    e.preventDefault()
    setIsImageUpdating(true)

    try {
      const formData = new FormData();
      formData.append("_id", editedData?._id)
      formData.append("bannerImage", bannerImage);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("level", formData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        e.target.reset()
        setIsOpenUpdateImageModal(false);
        getLevels()
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsImageUpdating(false)
    }
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const disableAddBtn =
    !inputData?.title?.trim() ||
    !inputData?.sequenceNo?.trim() ||
    !inputData?.pointsEarningPercent?.trim() ||
    (bannerImage === null || bannerImage === undefined);

  useEffect(() => {
    getLevels()
  }, []);

  return (
    <CContainer>
      <div className="mb-3 text-end">
        <button
          className="btn btn-warning"
          style={{ borderRadius: "20px" }}
          onClick={handleAddNewOpenModal}
        >
          ADD NEW LEVEL <FaPlus style={{ marginTop: "-4px" }} size={15} />
        </button>
      </div>
      <CRow>
        {levels.map((level) => (
          <CCol key={level.id} lg="4" md="6" className="mt-3">
            <CCard className="cardHover" style={{ borderRadius: '12px' }}>
              <CImage
                src={level.bannerImage ? level.bannerImage : 'assets/dummy-level-image.jpg'}
                alt="bannerimage"
                className="card-img-top"
                style={{ height: "55%", width: '100%', cursor: 'pointer' }}
                onClick={() => openUpdateImageModal(level)}
              />

              <CCardBody>
                <CRow>
                  <CCol>
                    <h6>Seq No : {level.sequenceNo}</h6>
                  </CCol>
                  <CCol>
                    <h5
                      className="fs-6"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                      }}
                    >
                      Title : {level.title}
                    </h5>
                  </CCol>
                </CRow>

                <CRow className='mt-2'>
                  <CCol>
                    <h5
                      className="fs-6"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                      }}
                    >
                      Required Points : {level.requiredPoints}
                    </h5>
                  </CCol>
                  <CCol>
                    <h5
                      className="fs-6"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                      }}
                    >
                      Point Earning % : {level.pointsEarningPercent}
                    </h5>
                  </CCol>
                </CRow>
                
                <div className="mb-1 mt-2 d-flex justify-content-around gap-2 align-items-center">
                  <div className='w-50' >
                    <CButton
                      color="primary"
                      onClick={() => handleEditModal(level)}
                      style={{
                        padding: '10px ',
                        fontWeight: "bold",
                        fontSize: "12px",
                        borderRadius: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        textTransform: "uppercase",
                        width: '100%'
                      }}
                      className="btn btn-primary me-2 btn-hover-danger"
                    >
                      <MdEdit size={15} /> <span className="">EDIT</span>
                    </CButton>
                  </div>
                  <div className='w-50'>
                    <CButton
                      color="danger"
                      onClick={() => handleDeleteModal(level)}
                      style={{
                        padding: '10px ',
                        fontWeight: "bold",
                        fontSize: "12px",
                        borderRadius: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        textTransform: "uppercase",
                        width: '100%'

                      }}
                      className="btn btn-danger me-2 btn-hover-danger"
                    >
                      <MdDelete size={15} /> <span className="">DELETE</span>
                    </CButton>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        ))}
      </CRow>

      {openDeleteModal ? (
        <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteLevel} isLoading={isDeleteBtnLoading} title="DELETE LEVEL" />
      ) : null}

      {addNewOpenModal ? (
        <CModal
          size="md"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD LEVEL</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addLevel}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Title <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  name="title"
                  value={inputData.title}
                  onChange={handleChange}
                />
              </div>
              <CRow>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Sequence No <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Sequence No"
                      className="form-control no-arrows"
                      name="sequenceNo"
                      value={inputData.sequenceNo}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Points Earning Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      max={100}
                      type="number"
                      placeholder="Points Earning Percent"
                      className="form-control no-arrows"
                      name="pointsEarningPercent"
                      value={inputData.pointsEarningPercent}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Booster Percent
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      max={100}
                      type="number"
                      placeholder="Booster Percent"
                      className="form-control no-arrows"
                      name="boosterPercent"
                      value={inputData.boosterPercent}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Required Points <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      placeholder="Required Points"
                      className="form-control no-arrows"
                      name="requiredPoints"
                      value={inputData.requiredPoints}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>


              </CRow>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Choose Banner Image <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  id="imageInput"
                  accept=".png,.jpg,.jpeg"
                  type="file"
                  onChange={handleBannerImageChange}
                  className="form-control"
                />
                <p className='text-danger mt-2' style={{ fontSize: '12px' }}>choose an image with dimensions equal to 1920x1080</p>
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton title="ADD LEVEL" isLoading={isLoading} isDisable={disableAddBtn} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          size="md"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#F5F5F5" }}>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT LEVEL
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Title <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  name="title"
                  value={editedData.title}
                  onChange={handleEditChange}
                />
              </div>
              <CRow>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Sequence No <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      step="any"
                      placeholder="Sequence No"
                      className="form-control no-arrows"
                      name="sequenceNo"
                      value={editedData.sequenceNo}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Points Earning Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      max={100}
                      type="number"
                      placeholder="Points Earning Percent"
                      className="form-control no-arrows"
                      name="pointsEarningPercent"
                      value={editedData.pointsEarningPercent}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Booster Percent
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      type="number"
                      placeholder="Booster Percent"
                      className="form-control no-arrows"
                      name="boosterPercent"
                      value={editedData.boosterPercent}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Required Points
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      placeholder="Required Points"
                      className="form-control no-arrows"
                      name="requiredPoints"
                      value={editedData.requiredPoints}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isOpenUpdateImageModal && (
        <CModal
          alignment="center"
          visible={isOpenUpdateImageModal}
          onClose={() => setIsOpenUpdateImageModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE IMAGE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditImage}>
            <CModalBody>
              <CForm>
                <CRow>
                  <CCol>
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">
                        Choose Banner Image <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        id="imageInput"
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={handleEditBannerImage}
                        className="form-control"
                      />
                      <p className='text-danger mt-2' style={{ fontSize: '12px' }}>choose an image with dimensions equal to 1920x1080</p>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton title="SAVE CHANGES" isLoading={isImageUpdating} />
            </CModalFooter>
          </CForm>
        </CModal>
      )
      }

    </CContainer>

  )
}

export default Level

import { CButton, CCol, CFormLabel, CFormSelect, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import axios from '../../api/axios';

const DaySummaryReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [subBtnLoading, setSubBtnLoading] = useState(false);
    const [printBtnLoading, setPrintBtnLoading] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [singleOutlet, setSingleOutlet] = useState('');
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [CouponCodeOrders, setCouponCodeOrders] = useState([]);
    const [totalOrdersSummary, setTotalOrdersSummary] = useState({});
    const [userInfo, setuserInfo] = useState([]);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const fetchOrderData = () => {
            const now = new Date();
            
            // Subtract one day to get yesterday's date
            const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
            
            // Set yesterday's date to 23:59:59.999
            yesterday.setHours(23, 59, 59, 999);
    
            // Set the start date to yesterday's date at 23:59:59.999
            setStartDate(yesterday);
        };
        
        fetchOrderData();
    }, []);    

    useEffect(() => {
        getOutlets();
    }, [])

    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
            setOutlets(activeOutlets);
          }
        } catch (error) {
          if (error?.response) {
            let errorMessage = "";
            if (error.response?.status === 406) {
              errorMessage =
                error?.response?.data?.error?.message ||
                error?.response?.data?.error;
            } else if (error.response?.status === 412) {
              errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
              errorMessage = error?.response?.data?.error;
            }
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const getOutletName = async (e) => {   
        const value = outlets?.filter(val => val._id === e )
        setSingleOutlet(value[0].name)  
    };

    const getSummary = async(date, outlet) => {
        setLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const updatedStartDate = date ? date.toISOString().split("T")[0] : null;
            // let url = `order/getOrdersForAdmin?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}&page=${page}&limit=${recordsPerPage}`;

            const reqBody = {
                date: updatedStartDate,
                outlet: outlet
            };
            
            const response = await axios.post("order/summary", reqBody, { headers });

            setTotalOrdersSummary(response?.data?.data?.totalOrdersSummary);
            setCouponCodeOrders(response?.data?.data?.couponCodeOrders);
            console.log("Coupons Discount :", response?.data?.data?.couponCodeOrders);
            
            setuserInfo(response?.data?.data?.userInfo[0]);

            if (response?.data?.status === true) {
                const ordersData = response?.data?.data?.totalOrdersData || [];
                  toast.success("Data Fetched Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                  });
              } else if (response?.data?.status === false) {
                toast.info("No records available. Please try again later.", {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                  errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                  errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                  errorMessage = error?.response?.data?.error;
                }
          
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async() => {
        setSubBtnLoading(true);
        setClicked(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999));

        await getSummary(adjustedStartDate, selectedOutlet);
        setSubBtnLoading(false);
    }    

    const handlePrintReport = () => {
        const tableContent = `
            <div style="margin: 20px;">
                <h3 style="text-align: center; margin-bottom: 20px;">DAY SUMMARY REPORT FOR - ${startDate?.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' })} | ${singleOutlet}</h3>
                <table style="border-collapse: collapse; width: 100%;">
                    <thead>
                        <tr>
                            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                            <th style="border: 1px solid black; padding: 8px; text-align: left;">PARTICULARS</th>
                            <th style="border: 1px solid black; padding: 8px; text-align: center; width: 300px">VALUE</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 14px">
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">1</td>
                            <td style="border: 1px solid black; padding: 8px;">TOTAL ORDERS [WITH COUPONS]</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center">${totalOrdersSummary?.totalCouponOrdersCount ? `${totalOrdersSummary?.totalCouponOrdersCount} nos` : "No data found" }</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">2</td>
                            <td style="border: 1px solid black; padding: 8px;">TOTAL ORDERS [WITHOUT COUPONS]</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center">${totalOrdersSummary?.totalNonCouponOrdersCount ? `${totalOrdersSummary?.totalNonCouponOrdersCount} nos` : "no data found" }</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">3</td>
                            <td style="border: 1px solid black; padding: 8px;">TOTAL KIOSK ORDERS</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${totalOrdersSummary?.totalKioskOrdersCount ? `${totalOrdersSummary?.totalKioskOrdersCount} nos` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">4</td>
                            <td style="border: 1px solid black; padding: 8px;">CANCELLED ORDERS</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${totalOrdersSummary?.totalCancelledOrdersCount ? `${totalOrdersSummary?.totalCancelledOrdersCount} nos` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">5</td>
                            <td style="border: 1px solid black; padding: 8px; font-weight: 600">TOTAL NO OF ORDERS</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: 600">${totalOrdersSummary?.totalOrdersCount ? `${totalOrdersSummary?.totalOrdersCount} nos` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">6</td>
                            <td style="border: 1px solid black; padding: 8px;">TOTAL COUPON DISCOUNT AMOUNT</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">₹. ${totalOrdersSummary?.totalCouponDiscount?.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">7</td>
                            <td style="border: 1px solid black; padding: 8px;">TOTAL KIOSK REVENUE</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${totalOrdersSummary?.totalKioskRevenue ? `₹. ${totalOrdersSummary?.totalKioskRevenue?.toFixed(2)}`: "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">8</td>
                            <td style="border: 1px solid black; padding: 8px; font-weight: 500">TOTAL REVENUE</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: 500">${totalOrdersSummary?.totalRevenue ? `₹. ${totalOrdersSummary?.totalRevenue?.toFixed(2)}` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">9</td>
                            <td style="border: 1px solid black; padding: 8px; font-weight: 500">CANCELLED ORDERS AMT</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: 500">${totalOrdersSummary?.totalCancelledOrdersRevenue ? `- ₹. ${totalOrdersSummary?.totalCancelledOrdersRevenue?.toFixed(2)}` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">10</td>
                            <td style="border: 1px solid black; padding: 8px; font-weight: 600">GRAND TOTAL</td>
                            <td style="border: 1px solid black; padding: 8px; text-align: center; font-weight: 600">${totalOrdersSummary?.totalRevenue ? `₹. ${(totalOrdersSummary.totalRevenue - totalOrdersSummary.totalCancelledOrdersRevenue).toFixed(2)}` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">11</td>
                            <td style="border: 1px solid black; padding: 8px;">NEW USERS JOINED COUNT</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${userInfo?.totalUsersCount ? `${userInfo?.totalUsersCount} nos` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">12</td>
                            <td style="border: 1px solid black; padding: 8px;">DELETED USERS</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${userInfo?.totalDeletedUsers ? `${userInfo?.totalDeletedUsers} nos` : "No data found"}</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">13</td>
                            <td style="border: 1px solid black; padding: 8px;">NEW USERS JOINED BY REFERRAL</td>
                            <td style="border: 1px solid black; padding: 8px;  text-align: center">${userInfo?.totalReferredUsers ? `${userInfo?.totalReferredUsers} nos` : "No data found"}</td>
                        </tr>
                        ${CouponCodeOrders?.map((coupon, index) => 
                            `<tr>
                                <td style="border: 1px solid black; padding: 8px;">${14 + index}</td>
                                <td style="border: 1px solid black; padding: 8px;">COUPON CODE - <span style: "font-weight: bold">${coupon?.coupon?.couponCode}</span></td>
                                <td style="border: 1px solid black; padding: 8px; text-align: center; font-size: 14px">${coupon?.count ? `${coupon?.count} nos | ` : "No data found"} ${coupon?.totalCouponDiscount !== undefined ? `₹. ${coupon?.totalCouponDiscount?.toFixed(2)}` : ""}</td>
                            </tr>`).join('') // Ensure to join the array into a string
                        }
                        
                    </tbody>
                </table>
            </div>
        `;
    
        // Open a new window for printing
        const printWindow = window.open("", "_blank", "width=900,height=600");
    
        // Write the content and style for printing
        printWindow.document.write(`
            <html>
                <head>
                    <title>Printable Report Summary</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        table { border-collapse: collapse; width: 100%; }
                        th, td { border: 1px solid black; padding: 8px; text-align: left; }
                        th { background-color: #f2f2f2; }
                        .header { text-align: center; margin-bottom: 20px; }
                        .header h2 { margin: 0; font-size: 20px; }
                    </style>
                </head>
                <body>
                    ${tableContent}
                </body>
            </html>
        `);
    
        // Close the document stream and trigger print
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };
    
    return (
        <div className='container'>
            <h5>DAY SUMMARY REPORT FOR - {startDate?.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' })} </h5>
            <CRow className='mt-3'>
                <CCol md={4} lg={4}>
                    <CFormLabel className="fw-semibold">Select Outlet <span className='text-danger'>*</span></CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => {
                            const newOutlet = e.target.value;
                            setSelectedOutlet(newOutlet);
                            getOutletName(newOutlet);
                        }}
                    >
                        <option value="">All Outlets</option>

                        {outlets.map((outName, index) => {
                        return (
                            <option key={index} value={outName?._id}>
                            {outName?.name}
                            </option>
                        );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={3} lg={3}>
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        <CFormLabel className="fw-semibold">Select Date <span className='text-danger'>*</span></CFormLabel>
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            placeholderText="Select Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md="2" lg="2" className="text-center">
                    <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                    <CButton className="btn rounded-pill w-100" onClick={handleSubmit}>
                        {subBtnLoading ? <CSpinner size="sm" /> : "Show Report"}
                    </CButton>
                </CCol>
                <CCol md="2" lg="2" className="text-center">
                    <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                    <CButton className="btn rounded-pill w-100 bg-success border-success" disabled={printBtnLoading} onClick={handlePrintReport}>
                        {printBtnLoading ? <CSpinner size="sm" /> : "Print Report"}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-4">
                {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <CSpinner color="primary" />
                        </div>
                    ) : clicked === false ? ( // Check if outlet and date are selected
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <h5 className="text-muted">Please Select Outlet and Required Date</h5>
                        </div>
                    ) : (
                        <CTable bordered responsive>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell>#</CTableHeaderCell>
                                    <CTableHeaderCell>PARTICULARS</CTableHeaderCell>
                                    <CTableHeaderCell style={{ width: '480px' }} className='text-center'>VALUE</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell>1</CTableDataCell>
                                    <CTableDataCell>TOTAL ORDERS [with Coupons]</CTableDataCell>
                                    <CTableDataCell className='text-center'>{totalOrdersSummary?.totalCouponOrdersCount ? `${totalOrdersSummary.totalCouponOrdersCount} nos` : "No Data Found"} </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>2</CTableDataCell>
                                    <CTableDataCell>TOTAL ORDERS [without Coupons]</CTableDataCell>
                                    <CTableDataCell className='text-center'>{totalOrdersSummary?.totalNonCouponOrdersCount ? `${totalOrdersSummary?.totalNonCouponOrdersCount} nos`: "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>3</CTableDataCell>
                                    <CTableDataCell>TOTAL KIOSK ORDERS</CTableDataCell>
                                    <CTableDataCell className='text-center'>{totalOrdersSummary?.totalKioskOrdersCount ? `${totalOrdersSummary?.totalKioskOrdersCount} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>4</CTableDataCell>
                                    <CTableDataCell>CANCELLED ORDERS</CTableDataCell>
                                    <CTableDataCell className='text-center text-danger'>{totalOrdersSummary?.totalCancelledOrdersCount ? `${totalOrdersSummary?.totalCancelledOrdersCount} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>5</CTableDataCell>
                                    <CTableDataCell className='fw-semibold'>TOTAL NO OF ORDERS</CTableDataCell>
                                    <CTableDataCell className='text-center fw-semibold text-success'>{totalOrdersSummary?.totalOrdersCount ? `${totalOrdersSummary?.totalOrdersCount} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>6</CTableDataCell>
                                    <CTableDataCell>TOTAL COUPON DISCOUNT AMT</CTableDataCell>
                                    <CTableDataCell className='text-center'>{totalOrdersSummary?.totalCouponDiscount ? `₹. ${totalOrdersSummary?.totalCouponDiscount?.toFixed(2)}` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>7</CTableDataCell>
                                    <CTableDataCell>TOTAL KIOSK REVENUE</CTableDataCell>
                                    <CTableDataCell className='text-center'>{totalOrdersSummary?.totalKioskRevenue ? `₹. ${totalOrdersSummary?.totalKioskRevenue?.toFixed(2)}` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>8</CTableDataCell>
                                    <CTableDataCell className='text-primary'>TOTAL REVENUE</CTableDataCell>
                                    <CTableDataCell className='text-center text-primary'>{totalOrdersSummary?.totalRevenue ? `₹. ${totalOrdersSummary?.totalRevenue?.toFixed(2)}` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>9</CTableDataCell>
                                    <CTableDataCell className='text-danger'>CANCELLED ORDERS AMT</CTableDataCell>
                                    <CTableDataCell className='text-center text-danger'> {totalOrdersSummary?.totalCancelledOrdersRevenue ? `- ₹. ${totalOrdersSummary?.totalCancelledOrdersRevenue?.toFixed(2)}` : "No Data Found"} </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>10</CTableDataCell>
                                    <CTableDataCell className='fw-semibold text-success'>GRAND TOTAL</CTableDataCell>
                                    <CTableDataCell className='text-center fw-semibold text-success'>
                                        {totalOrdersSummary?.totalRevenue
                                        ? `₹. ${(totalOrdersSummary.totalRevenue - totalOrdersSummary.totalCancelledOrdersRevenue).toFixed(2)}`
                                        : "No Data Found"}
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>11</CTableDataCell>
                                    <CTableDataCell>NEW USERS JOINED</CTableDataCell>
                                    <CTableDataCell className='text-center'>{userInfo?.totalUsersCount ? `${userInfo?.totalUsersCount} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>12</CTableDataCell>
                                    <CTableDataCell>DELETED USERS</CTableDataCell>
                                    <CTableDataCell className='text-center'>{userInfo?.totalDeletedUsers ? `${userInfo?.totalDeletedUsers} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>13</CTableDataCell>
                                    <CTableDataCell>NEW USERS JOINED BY REFERRAL</CTableDataCell>
                                    <CTableDataCell className='text-center'>{userInfo?.totalReferredUsers ? `${userInfo?.totalReferredUsers} nos` : "No Data Found"}</CTableDataCell>
                                </CTableRow>
                                {CouponCodeOrders?.map((coupon, index) => (
                                    <CTableRow key={index}>
                                        <CTableDataCell>{14 + index}</CTableDataCell>
                                        <CTableDataCell ><span>COUPON CODE - </span> <span className='text-primary fw-semibold'>{coupon?.coupon?.couponCode}</span></CTableDataCell>
                                        <CTableDataCell className='text-center'>{coupon?.count ? `${coupon?.count} nos  ` : "No Data Found" } {coupon?.totalCouponDiscount !== undefined ? ` | ₹. ${coupon?.totalCouponDiscount?.toFixed(2)}` : ""}</CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>
                    )
                }
            </CRow>
        </div>
    )
}

export default DaySummaryReport
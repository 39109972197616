import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import ReactDatePicker from "react-datepicker";

const ProductSalesReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [productList, setProductList] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState('');
    const [FilteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState(null);    
    const [clicked, setClicked] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
    
        const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);
        // getProductData(start, end, selectedOutlet, selectedCategory);

    }, []);

    useEffect(() => {
        getOutlets();
        getCategoryList();
    }, []);

    useEffect(() => {
        console.log("serach useEffect");
        
        if(searchText === "") {
          getProductData(startDate, endDate, selectedOutlet, selectedCategory);
        }
    }, [searchText]);

    const getProductData = async (startDate, endDate, outlet, category) => {
        try {
            setLoading2(true);
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
    
            const updatedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
            const updatedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;
    
            // Construct the query parameters based on the presence of outlet and category
            const queryParams = new URLSearchParams();
    
            if (updatedStartDate) queryParams.append('startDate', updatedStartDate);
            if (updatedEndDate) queryParams.append('endDate', updatedEndDate);
            if (outlet) queryParams.append('outlet', outlet);
            if (category) queryParams.append('category', category);
    
            const response = await axios.get(`order/getProductsReportForAdmin?${queryParams.toString()}`, { headers });
    
            console.log("Product Sales Response :", response?.data?.data);
            setProductList(response?.data?.data);
            setFilteredData(response?.data?.data);
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
    
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setLoading2(false);
        }
    };

    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            const filteredOutlets = response?.data?.data?.outlets.filter((act) => act?.status === "ACTIVE");
            console.log("Filtered Outlets :", filteredOutlets);
            setOutlets(filteredOutlets);
          }
        } catch (error) {
          if (error?.response) {
            let errorMessage = '';
            if (
              error.response?.status === 406
            ) {
              errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
            }
            else if (
              error.response?.status === 412
            ) {
              errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
              errorMessage = error?.response?.data?.error
            }
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const getCategoryList = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("masters/category/get", { headers });
    
          if (response?.data?.status === true) {
            setCategoryList(response?.data?.data?.categories);
          }
        } catch (error) {
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
          setError('End date cannot be earlier than start date');
        } else {
          setError('');
          setEndDate(date);
        }
    };

    const handleSubmit = async() => {
        setClicked(true);
        setBtnLoading(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

        
        // Log the formatted dates
        console.log("adjusted start :", adjustedStartDate);
        console.log("adjusted End :", adjustedEndDate);
        
        const formatDate = (date) => {
            return date.toISOString().split('T')[0]; // Format: '2024-07-31'
        };

        await getProductData(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedCategory);
        toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
        setBtnLoading(false);
    }

    const handlePrintReport = () => {
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });
          };
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const totalSales = productList.reduce((total, product) => total + product?.totalQuantity, 0);
        const totalRevenue = productList.reduce((total, product) => total + product?.totalRevenue, 0).toFixed(2);

        const tableContent = `
            <table style="border-collapse: collapse; width: 100%;">
                <thead>
                <tr style="font-size: 13px">
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Category</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Product Name</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Food Type</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">MRP</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Offer Price</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left;">Sales Count</th>
                    <th style="border: 1px solid black; padding: 8px; text-align: left; width: 90px;">Revenue</th>
                </tr>
                </thead>
                <tbody>
                ${productList?.length > 0 ? 
                    productList.map((productInfo, index) => `
                        <tr>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px">${index + 1}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px">${productInfo?.categoryName}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px;">${productInfo?.product?.name}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 11px; width: 60px">${productInfo?.product?.foodType}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.product?.mrp}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.product?.offerPrice}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: center">${productInfo?.totalQuantity}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 12px; text-align: right">₹ ${productInfo?.totalRevenue?.toFixed(2)}</td>
                        </tr>
                    `).join("") + `
                <tr style="font-weight: bold;">
                    <td colspan="6" style="border: 1px solid black; padding: 8px; text-align: right">Total</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: center">${totalSales}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: right">₹ ${totalRevenue}</td>
                </tr>` :
                    `<tr>
                        <td colspan="8" style="border: 1px solid black; padding: 8px; text-align: center;">No Orders Found</td>
                    </tr>`
                }
                </tbody>
            </table>
        `;

        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
        <html>
            <head>
            <title>Product Wise Sales Report</title>
            <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
            </style>
            </head>
            <body onload="window.print();window.close()">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <h2>Product Wise Sales Report</h2>
                <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
            </div>
            <div>${tableContent}</div>
            <div style="margin-top: 20px; text-align: right; font-size: 15px;">
                
            </div>
            </body>
        </html>
        `);
        win.document.close();

    }

    const handleSearchProducts = () => {
        const trimmedSearchText = searchText.trim().toLowerCase(); 
    
        if (trimmedSearchText) {
            const filtered = productList.filter(product => {
                const productName = product.product?.name?.toLowerCase(); // Make sure the name is a string and convert to lowercase
                return productName.includes(trimmedSearchText); // Check if the product name includes the search text
            });
            setFilteredData(filtered); // Set the filtered data
        } else {
            setFilteredData(productList); // Reset to the full product list if search text is empty
        }
    };
    
    return (
        <div className='container'>
            <CRow>
                <h5>PRODUCT SALES REPORTS</h5>
            </CRow>
            <CRow className='mt-3'>
                <CCol md={3}>
                    <CFormLabel className='fw-semibold'>Outlet</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                        <option value="">All Outlets</option>

                        {outlets?.map((outName, index) => {
                            return (
                                <option key={index} value={outName?._id}>
                                    {outName?.name}
                                </option>
                            );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={3}>
                    <CFormLabel className='fw-semibold'>Category</CFormLabel>
                    <CFormSelect id='selectField' value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        <option value="">All Category</option>
                        {categoryList.map((rm, index) => (
                            <option key={index} value={rm._id}>
                                {rm.name}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                        selected={startDate}
                        dateFormat="dd-MM-yyyy"  // Set the date format here
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select Start Date"
                        className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
                    </div>
                </CCol>
                <CCol xs={12} md={2}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="primary" className="w-100" onClick={handleSubmit} 
                        disabled={btnLoading}
                    >
                        {btnLoading ? <CSpinner size="sm" /> : 'Submit'} 
                        {/* Submit */}
                    </CButton>
                </CCol>
            </CRow>

            <CRow className='mt-4'>
                <CCol md="5" lg="6">
                    <CFormInput
                    type="text"
                    placeholder="Search By Product Name"
                    onChange={(e) => setSearchText(e.target.value)}
                    />
                </CCol>
                <CCol md="3" lg="3" className="text-center">
                    <CButton className="btn rounded-pill w-100" disabled={!searchText} onClick={handleSearchProducts}>
                        Search
                    </CButton>
                </CCol>
                <CCol xs={12} md={3} lg={3} className="text-center">
                    <CButton color="success" className="btn rounded-pill w-100" onClick={handlePrintReport}>
                        Print Report
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-4">
                <CCol>
                { clicked === false ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <h5 className="text-muted">Please Select Outlet, Category and Required Date</h5>
                    </div>
                    ) : (
                        <CTable striped bordered hover responsive>
                            <CTableHead>
                                <CTableRow style={{ fontSize: '15px' }}>
                                    <CTableHeaderCell>Sr no</CTableHeaderCell>
                                    <CTableHeaderCell>Category</CTableHeaderCell>
                                    <CTableHeaderCell>Product Name</CTableHeaderCell>
                                    <CTableHeaderCell>Food Type</CTableHeaderCell>
                                    <CTableHeaderCell>MRP</CTableHeaderCell>
                                    <CTableHeaderCell>Offer Price</CTableHeaderCell>
                                    <CTableHeaderCell>Sales Count</CTableHeaderCell>
                                    <CTableHeaderCell>Revenue</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {loading2 ? (
                                    <CTableRow>
                                        <CTableDataCell colSpan="8" className="text-center">
                                            <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                            {/* <p>Loading Data...</p> */}
                                        </CTableDataCell>
                                    </CTableRow>
                                ) : (
                                    FilteredData?.length > 0 ? (
                                        <>
                                            {FilteredData.map((product, index) => (
                                                <CTableRow key={product?.id} style={{ fontSize: '15px' }}>
                                                    <CTableDataCell>{index + 1}</CTableDataCell>
                                                    <CTableDataCell>{product?.categoryName}</CTableDataCell>
                                                    <CTableDataCell style={{ width: '25%', wordBreak: "break-word" }}>{product?.product?.name}</CTableDataCell>
                                                    <CTableDataCell style={{ color: product.product?.foodType === "NON-VEG" ? 'red' : 'green' }}>{product?.product?.foodType}</CTableDataCell>
                                                    <CTableDataCell className="text-end">₹ {product?.product?.mrp}</CTableDataCell>
                                                    <CTableDataCell className="text-end">₹ {product?.product?.offerPrice}</CTableDataCell>
                                                    <CTableDataCell className="text-end">{product?.totalQuantity}</CTableDataCell>
                                                    <CTableDataCell className="text-end">₹ {product?.totalRevenue?.toFixed(2)}</CTableDataCell>
                                                </CTableRow>
                                            ))}
                                            {/* Calculate total sales and total revenue */}
                                            <CTableRow className="fw-bold">
                                                <CTableDataCell colSpan="6" className="text-end">Total</CTableDataCell>
                                                <CTableDataCell className="text-end">
                                                    {FilteredData.reduce((totalSales, product) => totalSales + product?.totalQuantity, 0)}
                                                </CTableDataCell>
                                                <CTableDataCell className="text-end">
                                                    ₹ {FilteredData.reduce((totalRevenue, product) => totalRevenue + product?.totalRevenue, 0).toFixed(2)}
                                                </CTableDataCell>
                                            </CTableRow>
                                        </>
                                    ) : (
                                        <CTableRow>
                                            <CTableDataCell colSpan="8" className="text-center text-danger fw-5">
                                                No Products Found
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                )}
                            </CTableBody>
                        </CTable>
                    ) }

                </CCol>
            </CRow>
        </div>
    )
}

export default ProductSalesReport
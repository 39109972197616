import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { toast } from 'react-toastify';

const KioskUserReport = () => {

    const accessToken = localStorage.getItem('authToken');
    const [kioskUserData, setKioskUserData] = useState([]);
    const [allKioskUsersData, setAllKioskUsersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);
    const [printBtnLoading, setPrintBtnLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState('');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

    useEffect(() => {
        const fetchCustomerData = () => {
          if (searchText === "") {
            getKioskUsers(currentPage);
          }
        };
        fetchCustomerData();
    }, [recordsPerPage, searchText]);

    const handlePageChange = (page) => {
        console.log(" page : ", page);

        if (page > 0 && page <= totalPages) {
        setCurrentPage(page);
        }

        getKioskUsers(page, searchText);
    }

    const getKioskUsers = async(page, search) => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            // let url =  `kiosk-user?limit=${recordsPerPage}&page=${page}`;
            // if(search !== "" && search !== undefined) {
            //     url += `&search=${search}`
            // }
            let url = `kiosk-user`;

            if (search && search !== "") {
                url += `?search=${search}`;
            } else {
                // Only add pagination parameters if there's no search query
                url += `?limit=${recordsPerPage}&page=${page}`;
            }
            const response = await axios.get(url, { headers });
            console.log("Kisok response : ", response?.data?.data);
            setKioskUserData(response?.data?.data?.kioskUsers);
            setTotalPages(response?.data?.data?.pagination?.pages);
            setFilteredData(response?.data?.data?.kioskUsers); 
        } catch (error) {
            console.log("Error :", error);
        }
    }

    const getAllKioskUsers = async() => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            const response = await axios.get("kiosk-user", { headers });
            console.log("Kisok response : ", response?.data?.data?.kioskUsers);
            setAllKioskUsersData(response?.data?.data?.kioskUsers);
        } catch (error) {
            console.log("Error :", error);            
        }
    }

    const handleSearchKioskUsers = () => {
        const trimmedSearchText = searchText.trim().toLowerCase();
        if (trimmedSearchText) {
            const filtered = kioskUserData.filter(order => {
                const userName = order?.name?.toLowerCase() || '';  // Ensure name is a string and convert to lowercase
                const userEmail = order?.email?.toLowerCase() || '';  // Ensure email is a string and convert to lowercase
                const userMobile = order?.phone || '';  // Mobile doesn't need lowercase as it's usually numeric
        
                // Combine name, email, and mobile into a single string for easy searching
                const combinedSearchString = `${userName} ${userEmail} ${userMobile}`;
        
                // Check if the combined string includes the trimmed search text
                return combinedSearchString.includes(trimmedSearchText);
            });
    
            setFilteredData(filtered);
        } else {
            setFilteredData(kioskUserData); // Reset to all data if search text is empty
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setSubmitBtnLoading(true);
        await getKioskUsers(currentPage, searchText);
        toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
        setSubmitBtnLoading(false);
    }

    const handlePrintReport = async() => {

        setPrintBtnLoading(true);
        // Fetch all order data for the report
        const allOrdersData = await getAllKioskUsers();
        setPrintBtnLoading(false);

        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr style="font-size: 12px">
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Date of First Order</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Date of Last Order</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Customer Name</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Email</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Mobile</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Total Orders Placed</th>
              </tr>
            </thead>
            <tbody>
              ${allKioskUsersData?.length > 0 ? 
                allKioskUsersData?.map((customerInfo, index) => `
                  <tr style="font-size: 11px">
                    <td style="border: 1px solid black; padding: 8px; ">${index + 1}</td>
                    <td style="border: 1px solid black; padding: 8px; width: 120px">${customerInfo?.firstCreatedAt}</td>
                    <td style="border: 1px solid black; padding: 8px; width: 120px">${customerInfo?.lastCreatedAt}</td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 140px">${customerInfo?.name}</td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 180px">${customerInfo?.email}</td>
                    <td style="border: 1px solid black; padding: 8px; word-break: break-word; width: 120px">${customerInfo?.phone}</td>
                    <td style="border: 1px solid black; padding: 8px;">${customerInfo?.repeatCount}</td>
                  </tr>
                `).join("") :
                `<tr>
                  <td colspan="8" style="border: 1px solid black; padding: 8px; text-align: center;">No Customers Found</td>
                </tr>`
              }
            </tbody>
          </table>
        `;
    
        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>Customers Report</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
              <h2>Customers Report</h2>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    }

    return (
        <div className='container'>
            <h5>KIOSK USERS REPORTS</h5>

            <CRow className="mt-3">
                <CCol md="5" lg="6">
                    <CFormInput
                    type="text"
                    placeholder="Search By Name or Mobile or Email" 
                    onChange={(e) => setSearchText(e.target.value)}
                    />
                </CCol>
                <CCol md="3" lg="3" className="text-center">
                    <CButton className="btn rounded-pill w-100" onClick={handleSubmit} >
                      {submitBtnLoading ? <CSpinner size="sm" /> : "Search"}
                    </CButton>
                </CCol>
                <CCol md="4" lg="3" className="text-center">
                    <CButton className="btn rounded-pill w-100 bg-success border-success" onClick={handlePrintReport}>
                        { printBtnLoading ? <CSpinner size='sm' /> : "Print Report" }  
                    </CButton>
                </CCol>
            </CRow>

            <CRow className="mt-3">
                <CCol>
                    {/* Dropdown for selecting records per page */}
                    <CRow>
                        <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                            <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                <CFormSelect
                                className="ml-3"
                                style={{ width: 'auto' }}
                                value={recordsPerPage}
                                onChange={(e) => setRecordsPerPage(e.target.value)}
                                >
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                    <option value={400}>400</option>
                                    <option value={500}>500</option>
                                </CFormSelect>
                            </div>
                            <CPaginationItem
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Previous
                            </CPaginationItem>
                            {totalPages > 15 ? (
                                <>
                                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                    const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                    // Avoid rendering out-of-range page numbers
                                    if (pageNumber <= totalPages) {
                                        return (
                                        <CPaginationItem
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                        >
                                            {pageNumber}
                                        </CPaginationItem>
                                        );
                                    }
                                    return null;
                                    })}

                                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                </>
                                ) : (
                                // If totalPages is 15 or less, just display all page numbers
                                Array.from({ length: totalPages }, (_, i) => (
                                    <CPaginationItem
                                    key={i + 1}
                                    active={i + 1 === currentPage}
                                    onClick={() => handlePageChange(i + 1)}
                                    >
                                    {i + 1}
                                    </CPaginationItem>
                                ))
                                )
                            }
                            <CPaginationItem
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Next
                            </CPaginationItem>
                        </CPagination>
                    </CRow>

                    
                    <CTable striped bordered hover responsive>
                        <CTableHead>
                            <CTableRow style={{ fontSize: '15px' }}>
                            <CTableHeaderCell>#</CTableHeaderCell>
                            <CTableHeaderCell>Date of First Order</CTableHeaderCell>
                            <CTableHeaderCell>Date of Last Order</CTableHeaderCell>
                            <CTableHeaderCell>Customer Name</CTableHeaderCell>
                            <CTableHeaderCell>Email</CTableHeaderCell>
                            <CTableHeaderCell>Mobile</CTableHeaderCell>
                            <CTableHeaderCell>Total Orders Placed</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {loading ? (
                                <CTableRow>
                                    <CTableDataCell colSpan="10" className="text-center">
                                    <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                    </CTableDataCell>
                                </CTableRow>
                            ) :
                            filteredData?.length > 0 ? (
                                <>
                                    {filteredData?.map((customerData, index) => (
                                        <CTableRow key={index} style={{ fontSize: '15px' }}>
                                            <CTableDataCell>{index+1}</CTableDataCell>
                                            <CTableDataCell>{customerData?.firstCreatedAt}</CTableDataCell>
                                            <CTableDataCell>{customerData?.lastCreatedAt}</CTableDataCell>
                                            <CTableDataCell >{customerData?.name}</CTableDataCell>
                                            <CTableDataCell >{customerData?.email}</CTableDataCell>
                                            <CTableDataCell >{customerData?.phone}</CTableDataCell>
                                            <CTableDataCell className="text-end" style={{ width: '170px' }}>{customerData?.repeatCount}</CTableDataCell>
                                        </CTableRow>
                                    ))}
                                </>
                            ) : (
                                <CTableRow>
                                    <CTableDataCell colSpan="9" className="text-center text-danger fw-5">
                                    No Customers Found
                                    </CTableDataCell>
                                </CTableRow>
                            )}
                        </CTableBody>
                    </CTable>
                    <CRow>
                        <CPagination className="mt-1 justify-content-end" style={{ cursor: 'pointer' }}>
                        <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                            <CFormSelect
                            className="ml-3"
                            style={{ width: 'auto' }}
                            value={recordsPerPage}
                            onChange={(e) => setRecordsPerPage(e.target.value)}
                            >
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={300}>300</option>
                            <option value={400}>400</option>
                            <option value={500}>500</option>
                            </CFormSelect>
                        </div>
                        <CPaginationItem
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </CPaginationItem>
                        {totalPages > 15 ? (
                            <>
                                {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                                {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;

                                // Avoid rendering out-of-range page numbers
                                if (pageNumber <= totalPages) {
                                    return (
                                    <CPaginationItem
                                        key={pageNumber}
                                        active={pageNumber === currentPage}
                                        onClick={() => handlePageChange(pageNumber)}
                                    >
                                        {pageNumber}
                                    </CPaginationItem>
                                    );
                                }
                                return null;
                                })}

                                {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                            </>
                            ) : (
                            // If totalPages is 15 or less, just display all page numbers
                            Array.from({ length: totalPages }, (_, i) => (
                                <CPaginationItem
                                key={i + 1}
                                active={i + 1 === currentPage}
                                onClick={() => handlePageChange(i + 1)}
                                >
                                {i + 1}
                                </CPaginationItem>
                            ))
                            )
                        }
                        <CPaginationItem
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </CPaginationItem>
                        </CPagination>
                    </CRow>
                </CCol>
            </CRow>
        </div>
    )
}

export default KioskUserReport
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CFooter, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components';
import Select from 'react-select';

const AddAdvertisement = () => {
  const accessToken = localStorage.getItem("authToken");
  const [productsList, setProductsList] = useState([]);
  const [inputData, setInputData] = useState({
    title: "",
    type: "",
    sequenceNo: "",
    product: "",
    isTopImage: "",
    isBottomImage: "",
    link: ""
  });
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  }

  const handleImageChange = () => {
    const input = document.getElementById("imageInput");
    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      // reader.onloadend = () => {
      //   const image = new Image();
      //   image.src = reader.result;

      //   image.onload = () => {
      //     const width = image.width;
      //     const height = image.height;

      //     const aspectRatio = width / height;
      //     const targetAspectRatio = 16 / 9;

      //     if (Math.abs(aspectRatio - targetAspectRatio) < 0.01) {
      //       alert("Image dimensions are valid!");
      //       setImage(file);
      //     } else {
      //       alert(
      //         "Image dimensions are not valid. Please choose an image with a 16:9 aspect ratio."
      //       );
      //       input.value = "";
      //     }
      //   };
      // };

      setImage(file);

      reader.readAsDataURL(file);
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const addAdvertisement = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const formData = new FormData();
      formData.append("title", inputData.title);
      formData.append("type", inputData.type);
      formData.append("sequenceNo", inputData.sequenceNo);
      if (inputData?.product !== "") {
        formData.append("product", inputData.product);
      }
      if (inputData?.isTopImage !== "") {
        formData.append("isTopImage", inputData.isTopImage);
      }
      if (inputData?.isBottomImage !== "") {
        formData.append("isBottomImage", inputData.isBottomImage);
      }
      if (inputData?.link !== "") {
        formData.append("link", inputData.link);
      }
      formData.append("adImage", image);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("advertisement", formData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        e.target.reset();
        setInputData({
          title: '',
          type: '',
          sequenceNo: '',
          product: '',
          link: '',
          isTopImage: '',
          isBottomImage: ''
        })
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if ( error.response?.status === 412 ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getProductsList()
  }, [])

  return (
    <CContainer className='d-flex justify-content-center'>
      <CCard 
      // className='w-100 w-lg-100 w-md-100' 
        className='w-75 w-lg-75 w-md-90'
      >
        <CCardHeader>
          <CCardTitle className="fs-6 fw-semibold pt-1">
            ADD ADVERTISEMENT
          </CCardTitle>
        </CCardHeader>
        <CForm onSubmit={addAdvertisement}>
          <CCardBody>
            <CRow>
              <CCol>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Title"
                    className="form-control"
                    name="title"
                    value={inputData.title}
                    onChange={handleInputChange}
                  />
                </div>
              </CCol>
              <CCol>
                <div>
                  <CFormLabel className="fw-semibold">
                    Select Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    onChange={handleInputChange}
                    value={inputData?.type}
                    name="type"
                    className="mb-3"
                    aria-label="Small select example"
                  >
                    <option value="">Select Type</option>
                    <option value="CLICKABLE">CLICKABLE</option>
                    <option value="NON-CLICKABLE">NON CLICKABLE</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Sequence No. <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    placeholder="Sequence No."
                    className="form-control"
                    name="sequenceNo"
                    value={inputData?.sequenceNo}
                    onChange={handleInputChange}
                  />
                </div>
              </CCol>
              { inputData?.type === "NON-CLICKABLE" ? (
                <>
                  <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Top Image{" "}
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleInputChange}
                      name="isTopImage"
                      value={inputData?.isTopImage}
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option value="" disabled>Is it Top Image</option>
                      <option key="isTopImage" value="true">YES</option>
                      <option key="isTopImage" value="false">NO</option>
                    </CFormSelect>
                  </div>
                  </CCol>
                  <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Bottom Image{" "}
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleInputChange}
                      name="isBottomImage"
                      value={inputData?.isBottomImage}
                      className="mb-3"
                      aria-label="Small select example"
                    >
                      <option value="" disabled>Is It Bottom Image</option>
                        <option key="isBottomImage" value="true">YES</option>
                        <option key="isBottomImage" value="false">NO</option>
                    </CFormSelect>
                  </div>
                  </CCol>
                </>
              ) : (
                // <CCol>
                //   <div className="mb-3">
                //     <CFormLabel className="fw-semibold">
                //       Select Product{" "}
                //       <span className="text-danger">*</span>
                //     </CFormLabel>
                //     <CFormSelect
                //       size="sm"
                //       onChange={handleInputChange}
                //       name="product"
                //       value={inputData?.product}
                //       className="mb-3"
                //       aria-label="Small select example"
                //     >
                //       <option value="">Select Product</option>
                //       {productsList.map((rm) => (
                //         <option key={rm._id} value={rm._id}>
                //           {rm.name}
                //         </option>
                //       ))}
                //     </CFormSelect>
                //   </div>
                // </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Product <span className="text-danger">*</span>
                    </CFormLabel>
                    <Select
                      options={productsList.map((rm) => ({
                        value: rm._id,
                        label: rm.name,
                      }))}
                      value={
                        inputData?.product
                          ? productsList
                              .map((rm) => ({ value: rm._id, label: rm.name }))
                              .find((option) => option.value === inputData.product)
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (typeof selectedOption === 'object' && selectedOption !== null) {
                          handleInputChange({ target: { name: 'product', value: selectedOption.value } });
                        } else {
                          handleInputChange({ target: { name: 'product', value: '' } });
                        } 
                      }}
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Select Product"
                    />
                  </div>
                </CCol>
              ) }
              
            </CRow>

            <CRow>
              <CCol md="6" lg="6">
                <CFormLabel className='fw-semibold'>Add Link</CFormLabel>
                <CFormInput 
                  type="text"
                  placeholder="Add Link"
                  className="form-control"
                  name="link"
                  value={inputData.link}
                  disabled={inputData.type === "CLICKABLE"}
                  onChange={handleInputChange} 
                />
              </CCol>
              <CCol md="6" lg="6">
                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Choose File <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    onChange={handleImageChange}
                    className="form-control"
                    id="imageInput"
                  />
                  <p className='text-danger' style={{ fontSize: '13px' }}>Choose an image with 1920x1080 pixels</p>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <PrimaryButton title="ADD ADVERTISEMENT" isLoading={isLoading} />
          </CCardFooter>
        </CForm>
      </CCard>

    </CContainer>
  )
}

export default AddAdvertisement
